import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { restGraphqlAuth } from '../../services/restGraphql'
import { getUser } from '../../services/auth'

export const CasesTab = () => {
  return new Promise((resolve, reject) => {
    console.info('getUser',getUser())
    restGraphqlAuth({ myQuery: caseQuery, myVariables: { userId: getUser().id } })
      .then(result => {
        if (result?.success) {
          const cases = []
          if (result.cases)
            result.cases.forEach(cs => {
              cases.unshift(
                <Card key={cs.id} className="mb-3">
                  <Card.Body>
                    <Card.Title>{cs.subject}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Status: {cs.status}</Card.Subtitle>
                    <Card.Text>{cs.description}</Card.Text>
                    {/* <Card.Link href="#">Card Link</Card.Link> */}
                    {/* <Card.Link href="#">Another Link</Card.Link> */}
                  </Card.Body>
                </Card>
              )
            })
          
          if (cases.length === 0) cases.push(
            <span key="case">You currently have no cases. When you use the Contact Us form, a record of your contact requests will appear here.</span>
          )

          resolve(<Accordion>{cases}</Accordion>)
        } else {
          console.error(result)
          resolve(<>There was an error loading this page</>)
        }
      })
  })
}


const caseQuery = `
  query ($userId: ID!){
    cases (
      where: {created_by_user_id: $userId}, 
      sort: "created_at"
    ) {
      subject
      description
      status
      created_at
    }
  }
`