import React, { Component } from 'react'
import { getUser, isLoggedIn, logout, getToken } from '../services/auth'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import Navbar from '../components/nav'
import Footer from '../components/footer'
import Seo from '../components/seo'
import Container from "react-bootstrap/Container"
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { DocumentTab } from '../components/dashboard/documentsTab'
import { CasesTab } from '../components/dashboard/casesTab'


const caseManagerRoles = ['admin']


class Dashboard extends Component {

  handleLougout = () => {
    logout(() => navigate(`/login`))
  }


  handleCmsClick = () => {
    if(typeof window !== 'undefined') window.open(`${process.env.CMS_URL}/admin?login${encodeURIComponent(JSON.stringify({token:getToken(),user:getUser()}))}`,'_blank')
  }


  handleTabSelect = event => {
    this.setState({ selectedTab: event })
  }


  constructor() {
    super();

    
    if (!isLoggedIn()) {
      navigate(`/login`)
    } else {
      let activeTab = `link-0`
      
      try {
        const parsed = queryString.parse(this.props.location.search)
        if (parsed.tab) activeTab = parsed.tab
      } catch(e) {}

      this.state = {
        selectedTab: activeTab,
        documentTab: '',
        casesTab: '',
        tasksTab: '',
        profileTab: '',
      };
    }
  }


  componentDidMount() {
    DocumentTab().then(result => {
      this.setState({ documentTab: result })
    })
    CasesTab().then(result => {
      this.setState({ casesTab: result })
    })
  }


  render() {
    return (
      <>
        <Seo title="Dashboard" />
        <Navbar />

        <div className="about-heading-content py-5">
          <Container fluid>
            <Row>
              <Col className="mx-auto">
                <div className="bg-faded rounded p-5">
                  <h2 className="section-heading text-center mb-4">
                    {console.log(getUser())}
                    <span className="section-heading-upper">{getUser()?.name?.first_name + ` ` + getUser()?.name?.last_name}'s</span>
                    <span className="section-heading-lower">Dashboard</span>
                  </h2>
                  <Row>
                    <Col sm="auto">
                      <Nav defaultActiveKey={this.state.selectedTab} className="flex-column" id="dashboardTabs" onSelect={this.handleTabSelect}>
                        <Nav.Item>
                          <Nav.Link className="nav-link" eventKey="link-0">Documents</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="nav-link" eventKey="link-1">My Cases</Nav.Link>
                        </Nav.Item>
                        {caseManagerRoles.includes(getUser()?.role?.type) ?
                          <Nav.Item>
                            <Nav.Link className="nav-link" eventKey="link-2">Tasks</Nav.Link>
                          </Nav.Item> : <></>
                        }
                        <Nav.Item>
                          <Nav.Link className="nav-link" eventKey="link-3">My Profile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="nav-link" onClick={this.handleCmsClick}>Go to CMS <i className="fa fa-external-link"></i></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="nav-link" onClick={this.handleLougout}>Logout <i className="fa fa-sign-out"></i></Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col>
                      {this.state.selectedTab === 'link-0' ? this.state.documentTab : <></>}
                      {this.state.selectedTab === 'link-1' ? this.state.casesTab : <></>}
                      {this.state.selectedTab === 'link-2' ? this.state.tasksTab : <></>}
                      {this.state.selectedTab === 'link-3' ? this.state.profileTab : <></>}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </>
    )
  }
}

export default Dashboard
