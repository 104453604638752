import { getToken } from './auth'

export const restGraphqlAuth = (params) => {
    const {myQuery, myVariables} = params
    
    var myHeaders = new Headers()
    myHeaders.append(`Authorization`, `Bearer ${getToken()}`)
    myHeaders.append(`Content-Type`, `application/json`)

    var graphql = JSON.stringify({
        query: myQuery,
        variables: myVariables
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: graphql,
        redirect: 'follow'
    }

    return fetch(`${process.env.CMS_URL}/graphql`, requestOptions)
        .then(response => response.text())
        .then(str => {
            const res = JSON.parse(str)
            if (res.error) {
                throw res.error
            } else if (res.data) {
                return { success: true, ...res.data }
            }
        })
        .catch(error => {
            console.log('error', error)
            return { success: false, ...error }
        })
}