import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { restGraphqlAuth } from '../../services/restGraphql'
import { isGuestUser } from "../../services/auth";

export const DocumentTab = () => {
  return new Promise((resolve, reject) => {
    restGraphqlAuth({ myQuery: documentQuery })
      .then(result => {
        console.log(result)
        if (result.success) {
          const folders = []
          console.error(result)
          if (result.folders)
            result.folders.forEach(folder => {
              let files = []
              folder.documents.forEach(doc => {
                files.push(
                  <div key={doc.id}>
                    <i className="fa fa-file" style={{ color: '#33383a' }}></i>
                    <a className="file mx-3" href={doc.content.url} download={doc.name + doc.content.ext}>{doc.name}</a>
                    <br />
                  </div>
                )
              })

              if (files.length === 0) files.push(<span>This folder is empty</span>)

              folders.push(
                <Card key={folder.id} className="mt-2">
                  <Accordion.Toggle as={Card.Header} eventKey={folder.id}>
                    <h5 className="mb-0">
                      <i className="fa fa-folder" style={{ color: '#33383a' }}></i>
                      <span className="mx-3">
                        {folder.name}
                      </span>
                    </h5>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={folder.id}>
                    <Card.Body>
                      <div className="mx-4">
                        {files}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            })
          console.log('folders', folders)
          resolve(
            <Accordion>
              {folders.length ? folders : (isGuestUser() 
              ? <><p>You are a guest user</p><p>If there is no case in "My Cases" tab, please use the contact us form to request access to the private files.</p> </>
              : <p>There are no folders available at the moment</p>)}
            </Accordion>)
        } else {
          console.error(result)
          resolve(<> There was an error loading this page</>)
        }
      })
  })
}


const documentQuery = `
  query {
    folders {
    name
    id
    documents {
      id
      name
      created_at
      updated_at
      content {
        name 
        ext 
        size 
        url
      }
    }
  }
}
`